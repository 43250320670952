import axios from "./axios";
import { cacheData, getCachedData } from "../cache/StorageCache";

export const fetchPage = async (key: string) => {
    const data = getCachedData(key);

    if (data === null) {
        const response = await axios.get(`/api/page?key=${key}`);
        cacheData(key, response);

        return response;
    }

    return data;
}
