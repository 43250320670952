import tw from 'twin.macro'
import styled from 'styled-components'

export const FormSection = styled.section`
  ${tw`relative bg-white flex flex-col items-center justify-start gap-[32px] max-w-[1024px] text-center text-5xl text-darkslategray font-header-bold`}
`

export const FormInputsContainer = styled.div`
  ${tw`self-stretch flex flex-col items-center justify-start gap-[16px]`}
`

export const SectionHeader = styled.h3`
  ${ `font-inherit` }
  ${tw`m-0 self-stretch relative text-inherit leading-[40px] font-bold md:leading-[40px]`}
`

export const InputsListContainer = styled.div`
  ${tw`w-full flex flex-col items-start justify-start gap-[32px] max-w-[600px] text-left text-xs text-gray-100 font-text-regular-body`}
`

export const AgreementsContainer = styled.div`
  gap: 1rem;
  max-width: 37.5rem;
  width: 100%;
  ${tw`w-full flex flex-col items-center justify-start gap-[16px] max-w-[600px] text-left text-xs text-gray-100 font-text-regular-body`}
`

export const TwoColumnInputContainer = styled.div`
  ${tw`self-stretch flex flex-row items-start justify-start gap-[16px]`}
`
