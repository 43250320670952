import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import { GravePhoto } from "../types/Grave";

type GravePhotoDetails = {
    alt: string;
    photo: GravePhoto;
}

const StyledPhotoContainer = styled.div`
  ${tw`flex-1 relative rounded-2xl h-[15.74rem] overflow-hidden shrink-0 sm:self-stretch sm:w-auto sm:flex-[unset]`}
`

const StyledPhoto = styled.img`
  ${tw`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover sm:w-full`}
`

const GraveImage: FC<GravePhotoDetails> = ({photo, alt}) => {
  return (
      <StyledPhotoContainer>
          <StyledPhoto alt={ alt + ' | ' + photo.id } src={ photo.url } />
      </StyledPhotoContainer>
  );
};

export default GraveImage;
