import { FC } from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  AgreementsContainer,
  FormInputsContainer,
  FormSection,
  InputsListContainer,
  SectionHeader,
  TwoColumnInputContainer
} from "./SupportFormStyles";
import TextInput from "./TextInput";
import CheckboxInput from "./CheckboxInput";

type SupportFormType = {
  onSubmitFunction: CallableFunction;
  formValues?: any;
  minAmount: number;
}

const SupportForm: FC<SupportFormType> = ({ onSubmitFunction, formValues, minAmount }) => {
  const validationSchema = Yup.object({
    name: Yup.string().min(5, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    email: Yup.string().email('Wpisz poprawny adres email').required('Pole wymagane'),
    city: Yup.string().min(3, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    street: Yup.string().min(3, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    zipCode: Yup.string().min(3, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    houseNumber: Yup.string().min(1, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    apartmentNumber: Yup.string().min(1, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    country: Yup.string().min(1, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    amount: Yup.number().min(minAmount, `Minimalna kwota wsparcia to ${ minAmount } zł`).required('Pole wymagane'),
    hideMyData: Yup.bool(),
    acceptRegulations: Yup.bool().oneOf([true], 'Zgoda jest wymagana.').required('Pole wymagane'),
    acceptDataProcessing: Yup.bool().oneOf([true], 'Zgoda jest wymagana.').required('Pole wymagane'),
  });

  const initialValues = {
    name: "",
    email: "",
    city: "",
    street: "",
    zipCode: "",
    houseNumber: "",
    apartmentNumber: "",
    country: "",
    amount: minAmount,
    hideMyData: false,
    acceptRegulations: false,
    acceptDataProcessing: false,
  };

  const convertToString = (values: any) => {
    return JSON.stringify(values, null, 2);
  };

  return (
      <Formik
          initialValues={ formValues !== null && typeof formValues !== "undefined" ? formValues : initialValues }
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            onSubmitFunction(values);
          }}
      >
        {({values, errors, touched}) => (
            <Form id="graveSupportForm" className="w-full">
              <FormSection>
                <FormInputsContainer>
                  <SectionHeader>Dane patrona</SectionHeader>
                  <InputsListContainer>
                    <TextInput touched={ touched.email } errors={ errors.email } name="email" placeholder="Adres e-mail" />
                    <TextInput touched={ touched.name } errors={ errors.name } name="name" placeholder="Imię i nazwisko" />
                    <TextInput touched={ touched.street } errors={ errors.street } name="street" placeholder="Ulica" />
                    <TwoColumnInputContainer>
                      <TextInput touched={ touched.houseNumber } errors={ errors.houseNumber } name="houseNumber" placeholder="Numer domu" />
                      <TextInput touched={ touched.apartmentNumber } errors={ errors.apartmentNumber } name="apartmentNumber" placeholder="Numer lokalu" />
                    </TwoColumnInputContainer>
                    <TextInput touched={ touched.country } errors={ errors.country } name="country" placeholder="Kraj" />
                    <TextInput touched={ touched.city } errors={ errors.city } name="city" placeholder="Miasto" />
                    <TextInput touched={ touched.zipCode } errors={ errors.zipCode } name="zipCode" placeholder="Kod pocztowy" />
                    <TextInput touched={ touched.amount } errors={ errors.amount } name="amount" placeholder="Kwota" />

                    <CheckboxInput name="hideMyData" label="Ukryj moje dane na liście patronów" />
                  </InputsListContainer>
                </FormInputsContainer>
                <FormInputsContainer>
                  <SectionHeader>Zgody</SectionHeader>
                  <AgreementsContainer>
                    <CheckboxInput touched={ touched.amount } errors={ errors.amount } name="acceptRegulations" label="Oświadczam, że zapoznałem się z Regulaminem usługi." />
                    <CheckboxInput touched={ touched.amount } errors={ errors.amount } name="acceptDataProcessing" label="Wyrażam zgodę na przetwarzanie moich danych przez Fundacja Stare Powązki w zakresie patronatu." />
                  </AgreementsContainer>
                </FormInputsContainer>
              </FormSection>
            </Form>
        )}
      </Formik>
  );
};
export default SupportForm;
