import { FunctionComponent, useState, useEffect } from "react";
import CardSearchResultHistorical from "../components/CardSearchResultHistorical";
import ButtonPrimaryMediumDefault from "../components/ButtonPrimaryMediumDefault";
import { fetchGraves } from "../api/Grave";
import GraveType, {getFullName} from "../types/Grave";
import ButtonLoader from "../components/ButtonLoader";
import Layout from "../components/layout/Layout";
import {cacheData, getCachedData} from "../cache/StorageCache";
import PageHeader from "../components/PageHeader";

export const formatDate = (dateValue:string) => {
    return (new Date(dateValue)).toLocaleDateString('pl-PL', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
}

const Graves: FunctionComponent = () => {
    const [page, setPage] = useState(0);
    const [gravesCount, setGravesCount] = useState(0);
    const [graves, setGraves] = useState([]);
    const [loading, setLoading] = useState(false);

    const PageData = {
        siteTitle: 'Stare Powązki',
        pageTitle: 'Lista grobów',
        canonicalUrl: '/groby'
    }

  const loadGraves = () => {
        if (graves.length === 0) {
            const cachedAllGraves = getCachedData('all_graves_list');

            if (cachedAllGraves !== null) {
                setGraves(cachedAllGraves.content);
                setGravesCount(cachedAllGraves.totalElements);
                setPage(page + 1);
                return;
            }
        }

        setLoading(true);

        fetchGraves(page, 9).then(response => {
            setLoading(false);
            setPage(page + 1);
            setGraves(graves.concat(response.data.content));
            if (gravesCount === 0) {
                setGravesCount(response.data.totalElements);
                cacheData('all_graves_list', response.data);
            }
        })
        .catch(error => { console.error(error); });
  }

  const loadMoreGraves = () => {
      loadGraves();
  }

  useEffect(() => {
      if (page === 0) {
          loadGraves();
      }
  }, []);

  return (
      <Layout pageData={ PageData }>
          <section className="w-full flex flex-col items-center justify-start gap-[2.5rem] max-w-[64rem] text-left text-[2rem] text-darkslategray lg:w-full md:w-full">
              <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
                  <PageHeader>Wybierz grób do opieki</PageHeader>
                  <label className="cursor-default self-stretch relative text-[1rem] leading-[2rem] font-text-regular-body text-tan">
                      { gravesCount } pochowanych
                  </label>
              </div>
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[2rem] lg:w-full h-auto">
                  {
                      graves.map((grave:GraveType) => {
                          return <CardSearchResultHistorical
                              key={grave.id}
                              id={grave.id}
                              imageDimensions={grave.photo}
                              personName={ getFullName(grave) }
                              burialPlotNumber={ 'Kwatera: ' + grave.quarter }
                              imageCode="/iconcross.svg"
                              dateOfBirth={formatDate(grave.dateDeath)}
                          />
                      })
                  }
              </div>
              { loading && <ButtonLoader /> }

              { !loading && gravesCount > 9 && <ButtonPrimaryMediumDefault handleOnClick={() => loadMoreGraves()} buttonText="Pokaż więcej"/> }
          </section>
      </Layout>
  );
};

export default Graves;
