import { useState, useEffect, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { fetchGrave } from "../api/Grave";
import ButtonLoader from "../components/ButtonLoader";
import { SingleGraveType } from "../types/Grave";
import SupportForm from "../components/form/SupportForm";
import ButtonPrimaryMediumDefault from "../components/ButtonPrimaryMediumDefault";
import SupportPageHeader from "../components/SupportPageHeader";
import {
  GRAVE_SUPPORT_AMOUNT_MIN_GROUP,
  GRAVE_SUPPORT_AMOUNT_MIN_INDIVIDUAL,
  GRAVE_SUPPORT_TYPE_INDIVIDUAL,
  P24_SDK_VERSION,
  PAYMENT_TYPE_BANK_TRANSFER,
  Supporter
} from "../types/Support";
import SupportSummary from "../components/SupportSummary";
import SupportPayment from "../components/SupportPayment";
import PaymentConfirmationHeader from "../components/PaymentConfirmationHeader";
import PaymentConfirmationContent from "../components/PaymentConfirmationContent";
import RequestErrors from "../components/RequestErrors";
import {sendSupportRequest} from "../api/Support";

const GraveSupport: FC = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [grave, setGrave] = useState<SingleGraveType | null>(null);
  const [loading, setLoading] = useState(true);

  const [step, setStep] = useState(1);
  const [supporter, setSupporter] = useState<Supporter | null>(null);
  const [completeBankTransfer, setCompleteBankTransfer] = useState(false);
  const [requestErrors, setRequestErrors] = useState({});

  const finalizeSupport = (paymentType: string | null) => {
    const requestData = {
      ...supporter,
      type: type,
      graveId: grave?.id,
      paymentType: paymentType,
      p24SdkVersion: P24_SDK_VERSION,
      redirectOnComplete: `${window.location.protocol}//${window.location.host}/groby/wsparcie/potwierdzenie`
    }

    setLoading(true);

    sendSupportRequest(requestData).then((response) => {
      if (paymentType === PAYMENT_TYPE_BANK_TRANSFER) {
        setLoading(false);
        setCompleteBankTransfer(true);
      } else {
        window.location.href = process.env.REACT_APP_P24_ENDPOINT + response.data.data.token;
      }
    }).catch(error => {
      console.error(error);
      setRequestErrors({error: 'Wystąpił błąd serwera. Prosimy spróbować ponownie później.'});

      setLoading(false);
      setStep(1);
    });
  }

  const getConfirmationTitle = () => {
    return `#${ grave?.graveId } - patronat: ${ grave?.name } ${ grave?.lastName }: kwater: ${ grave?.quarter }; rząd: ${ grave?.row }; miejsce: ${ grave?.place }`
  }

  const getSupportType = () => {
    if (type === GRAVE_SUPPORT_TYPE_INDIVIDUAL) {
      return 'Indywidualny';
    }

    return 'Grupowy';
  }

  const getSupportMinAmount = () => {
    if (type === GRAVE_SUPPORT_TYPE_INDIVIDUAL) {
      return GRAVE_SUPPORT_AMOUNT_MIN_INDIVIDUAL;
    }

    return GRAVE_SUPPORT_AMOUNT_MIN_GROUP;
  }

  const nextStep = () => {
    if (step >= 1 && step < 3) { setStep(step + 1); }
  }

  const previousStep = () => {
    if (step > 1 && step <= 3) { setStep(step -1 ); }
  }

  const PageData = {
    siteTitle: 'Stare Powązki',
    pageTitle: `Wsparcie grobu: ${ grave?.lastName } (${ grave?.graveId })`,
    canonicalUrl: `/groby/wsparcie/${ type }/${ id }`
  }

  const onSubmit = (values: any) => {
    setSupporter(values);
    nextStep();
  };

  const loadGrave = () => {
    setLoading(true);

    fetchGrave(id).then(response => {
      if (!response.data.supportIndividual && !response.data.supportGroup) {
        navigate('/404');
      }

      setGrave(response.data);
      setLoading(false);
    }).catch(error => {
      navigate('/404');
    });
  }

  useEffect(() => {
    if (grave === null) { loadGrave(); }
  }, []);

  return (
    <Layout pageData={ PageData }>
      <>{ loading && <div className="w-full justify-center flex"><ButtonLoader /></div> }</>
      <>
        { !loading && grave !== null &&
            <>
              { !completeBankTransfer &&
                  <>
                    <SupportPageHeader fractionText={ `${step}/3` } patronText={ `Zostań patronem (${ getSupportType() })` } />
                    { step === 1 &&
                        <>
                          { Object.keys(requestErrors).length > 0 &&
                              <RequestErrors errors={ requestErrors } />
                          }
                          <SupportForm minAmount={ getSupportMinAmount() } formValues={ supporter } onSubmitFunction={ onSubmit } />
                        </>

                    }
                    { step === 2 &&
                        <SupportSummary supportType={ type } grave={ grave } amount={ supporter !== null ? supporter.amount : 0 } />
                    }
                    { step === 3 &&
                        <SupportPayment handlePaymentType={ finalizeSupport } />
                    }
                    <div className="w-full justify-center flex gap-2">
                      { step !== 1 &&
                          <>
                            <ButtonPrimaryMediumDefault handleOnClick={ previousStep } buttonType="button" buttonText="Wstecz"></ButtonPrimaryMediumDefault>
                            { step !== 3 &&
                                <ButtonPrimaryMediumDefault handleOnClick={ nextStep } buttonType="button" buttonText="Przejdź do płatności"></ButtonPrimaryMediumDefault>
                            }
                          </>
                      }
                      { step === 1 &&
                          <ButtonPrimaryMediumDefault formId="graveSupportForm" handleOnClick={ () => onSubmit } buttonType="submit" buttonText="Dalej"></ButtonPrimaryMediumDefault>
                      }
                    </div>
                  </>
              }
              { !loading && completeBankTransfer &&
                  <>
                    <PaymentConfirmationHeader />
                    <PaymentConfirmationContent title={ getConfirmationTitle() } amount={ supporter === null ? 0 : supporter.amount } />
                  </>
              }
            </>
        }
      </>
    </Layout>
  );
};

export default GraveSupport;
