import { FunctionComponent } from "react";
import Layout from "../components/layout/Layout";
import PaymentConfirmationHeader from "../components/PaymentConfirmationHeader";
import PaymentConfirmationContent from "../components/PaymentConfirmationContent";

const SupportConfirmation: FunctionComponent = () => {
  const PageData = {
      siteTitle: 'Stare Powązki',
      pageTitle: 'Potwierdzenie płatności.',
      canonicalUrl: '/groby/wsparcie/potwierdzenie'
  }

  return (
        <Layout pageData={ PageData }>
            <PaymentConfirmationHeader />
        </Layout>
  );
};

export default SupportConfirmation;
