export const Pages = {
    privacyPolicy: {
        key: 'PRIVACY_POLICY',
        page: {
            siteTitle: 'Stare Powązki',
            pageTitle: 'Polityka prywatności',
            canonicalUrl: '/polityka-prywatnosci'
        }
    },
    termsOfService: {
        key: 'PATRONAGE_TERMS_OF_SERVICE',
        page: {
            siteTitle: 'Stare Powązki',
            pageTitle: 'Regulamin Patronatu Grobów',
            canonicalUrl: '/regulamin'
        }
    },
};
