import { FunctionComponent } from "react";
import CardSearchResultHistorical from "./CardSearchResultHistorical";
import ButtonPrimaryMediumDefault from "./ButtonPrimaryMediumDefault";
import GraveType, {getFullName} from "../types/Grave";
import {Link} from "react-router-dom";

interface GravesArray {
    graves: Array<GraveType>
}

const FormContainer: FunctionComponent<GravesArray> = ({graves}) => {

    const formatDate = (dateValue:string) => {
        return (new Date(dateValue)).toLocaleDateString('pl-PL', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
    }

  return (
    <section className="w-full flex flex-col items-center justify-start gap-[2.5rem] max-w-[64rem] text-left text-[1.5rem] text-darkslategray font-header-bold lg:w-full lg:gap-[2.5rem] lg:pl-[0rem] lg:box-border lg:max-w-[64rem] md:w-full sm:gap-[1rem]">
      <h2 className="m-0 self-stretch relative text-inherit leading-[2rem] font-bold font-inherit lg:text-[1.5rem] lg:leading-[3rem] md:text-left sm:text-left">
        Wybrane Groby
      </h2>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[2rem] lg:w-full h-auto">
          {
              graves.map((grave) => {
                  return <CardSearchResultHistorical
                      key={grave.id}
                      id={grave.id}
                      imageDimensions={grave.photo}
                      personName={ getFullName(grave) }
                      burialPlotNumber={ 'Kwatera: ' + grave.quarter }
                      imageCode="/iconcross.svg"
                      dateOfBirth={formatDate(grave.dateDeath)}
                  />
              })
          }
      </div>
        <Link to={'/groby'} className="no-underline">
          <ButtonPrimaryMediumDefault buttonText="Zobacz wszystkie"/>
        </Link>
    </section>
  );
};

export default FormContainer;
