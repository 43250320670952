import { FunctionComponent } from "react";
import tw from 'twin.macro'
import styled from 'styled-components'

type PageHeaderType = {
  fractionText: string;
  patronText: string;
};

const PageHeaderSectionStyled = styled.section`
  position: unset;
  width: 100%;
  gap: 1rem;
  max-width: 64rem;
  ${tw`relative flex flex-col items-center justify-start gap-[16px] max-w-[1024px] text-center text-5xl text-darkslategray font-header-bold sm:pl-4 sm:pr-4 sm:box-border`}
`

const FractionTextHeader = styled.h2`
  font-size: 1.5rem;
  line-height: 2.5rem;
  ${ `font-inherit` }
  ${tw`m-0 self-stretch relative text-inherit leading-[40px] md:text-5xl md:leading-[40px] sm:text-5xl sm:leading-[40px]`}
`

const SupportPageHeader: FunctionComponent<PageHeaderType> = ({fractionText, patronText}) => {
  return (
    <PageHeaderSectionStyled>
      <FractionTextHeader className="font-normal">{ fractionText }</FractionTextHeader>
      <FractionTextHeader className="font-bold">{ patronText }</FractionTextHeader>
    </PageHeaderSectionStyled>
  );
};

export default SupportPageHeader;
