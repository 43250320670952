import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import TextCopy from "./TextCopy";

const StyledContainer = styled.div`${tw`self-stretch flex flex-col items-center justify-start`}`
const StyledInnerContainer = styled.div`${tw`w-[37.5rem] flex flex-col items-center justify-start gap-[1.5rem] max-w-[37.5rem] sm:w-full sm:pl-[1rem] sm:pr-[1rem] sm:box-border`}`

type ConfirmationContentType = {
    title: string;
    amount: number;
}

const PaymentConfirmationContent: FC<ConfirmationContentType> = ({ title, amount }) => {
  return (
      <StyledContainer>
          <StyledInnerContainer>
              <TextCopy title="Odbiorca:" text="Fundacja Stare Powązki" allowCopy={ true } />
              <TextCopy
                  title="Numer konta:"
                  text="77 1090 2590 0000 0001 4772 6410<br>Santander Bank Polska S.A."
                  allowCopy={ true }
              />
              <TextCopy
                  title="Tytułem"
                  text={ title }
                  allowCopy={ true }
              />
              <TextCopy
                  title="Kwota"
                  text={ amount + " zł" }
                  allowCopy={ false }
              />
          </StyledInnerContainer>
      </StyledContainer>
  );
};

export default PaymentConfirmationContent;
