import { FunctionComponent, useMemo, type CSSProperties } from "react";
import FooterLink from "./FooterLink";

type FooterType = {
  imageDimensions?: string;

  /** Style props */
  footerPosition?: CSSProperties["position"];
  footerWidth?: CSSProperties["width"];
  footerPadding?: CSSProperties["padding"];
  footerAlignSelf?: CSSProperties["alignSelf"];
  contentGap?: CSSProperties["gap"];
  contentMaxWidth?: CSSProperties["maxWidth"];
  logotypeFullHorizontalIcoWidth?: CSSProperties["width"];
  logotypeFullHorizontalIcoHeight?: CSSProperties["height"];
  linksPadding?: CSSProperties["padding"];
  linksGap?: CSSProperties["gap"];
  linkFontSize?: CSSProperties["fontSize"];
  linkLineHeight?: CSSProperties["lineHeight"];
  linkFontSize1?: CSSProperties["fontSize"];
  linkLineHeight1?: CSSProperties["lineHeight"];
  linkFontSize2?: CSSProperties["fontSize"];
  linkLineHeight2?: CSSProperties["lineHeight"];
  linkFontSize3?: CSSProperties["fontSize"];
  linkLineHeight3?: CSSProperties["lineHeight"];
  linkFontSize4?: CSSProperties["fontSize"];
  linkLineHeight4?: CSSProperties["lineHeight"];
  textpFontSize?: CSSProperties["fontSize"];
  textpLineHeight?: CSSProperties["lineHeight"];
};

const Footer: FunctionComponent<FooterType> = ({
  imageDimensions,
  footerPosition,
  footerWidth,
  footerPadding,
  footerAlignSelf,
  contentGap,
  contentMaxWidth,
  logotypeFullHorizontalIcoWidth,
  logotypeFullHorizontalIcoHeight,
  linksPadding,
  linksGap,
  linkFontSize,
  linkLineHeight,
  linkFontSize1,
  linkLineHeight1,
  linkFontSize2,
  linkLineHeight2,
  linkFontSize3,
  linkLineHeight3,
  linkFontSize4,
  linkLineHeight4,
  textpFontSize,
  textpLineHeight,
}) => {
  const FooterStyle: CSSProperties = useMemo(() => {
    return {
      position: footerPosition,
      width: footerWidth,
      padding: footerPadding,
      alignSelf: footerAlignSelf,
    };
  }, [
    footerPosition,
    footerWidth,
    footerPadding,
    footerAlignSelf,
  ]);

  const contentStyle: CSSProperties = useMemo(() => {
    return {
      gap: contentGap,
      maxWidth: contentMaxWidth,
    };
  }, [contentGap, contentMaxWidth]);

  const logotypeFullHorizontalIconStyle: CSSProperties = useMemo(() => {
    return {
      width: logotypeFullHorizontalIcoWidth,
      height: logotypeFullHorizontalIcoHeight,
    };
  }, [logotypeFullHorizontalIcoWidth, logotypeFullHorizontalIcoHeight]);

  const linksStyle: CSSProperties = useMemo(() => {
    return {
      padding: linksPadding,
      gap: linksGap,
    };
  }, [linksPadding, linksGap]);

  const linkStyle: CSSProperties = useMemo(() => {
    return {
      fontSize: linkFontSize,
      lineHeight: linkLineHeight,
    };
  }, [linkFontSize, linkLineHeight]);

  const link1Style: CSSProperties = useMemo(() => {
    return {
      fontSize: linkFontSize1,
      lineHeight: linkLineHeight1,
    };
  }, [linkFontSize1, linkLineHeight1]);

  const link2Style: CSSProperties = useMemo(() => {
    return {
      fontSize: linkFontSize2,
      lineHeight: linkLineHeight2,
    };
  }, [linkFontSize2, linkLineHeight2]);

  const link3Style: CSSProperties = useMemo(() => {
    return {
      fontSize: linkFontSize3,
      lineHeight: linkLineHeight3,
    };
  }, [linkFontSize3, linkLineHeight3]);

  const link4Style: CSSProperties = useMemo(() => {
    return {
      fontSize: linkFontSize4,
      lineHeight: linkLineHeight4,
    };
  }, [linkFontSize4, linkLineHeight4]);

  const textpStyle: CSSProperties = useMemo(() => {
    return {
      fontSize: textpFontSize,
      lineHeight: textpLineHeight,
    };
  }, [textpFontSize, textpLineHeight]);

  return (
    <footer
      className="relative w-full flex flex-col py-10 box-border items-center justify-start text-center text-sm text-dimgray-200 font-text-regular-body lg:box-border md:box-border sm:box-border mq350small:box-border"
      style={FooterStyle}
    >
      <div
        className="w-full flex flex-col items-center justify-start gap-[32px] max-w-[1024px] md:items-center md:justify-start sm:items-center sm:justify-start"
        style={contentStyle}
      >
        <img
          className="w-[219px] h-12 object-cover lg:h-8"
          alt=""
          src={imageDimensions}
          style={logotypeFullHorizontalIconStyle}
        />
        <div
          className="self-stretch flex flex-col py-4 px-0 items-center justify-start gap-[32px] md:self-stretch md:w-auto md:items-center md:justify-start md:pl-0 md:pr-0 md:box-border"
          style={linksStyle}
        >
          <div className="self-stretch flex flex-row flex-wrap items-start justify-between md:items-start md:justify-between md:items-center md:gap-2 md:flex-col sm:gap-[32px] sm:items-center sm:justify-start sm:pl-0 sm:box-border">
            <FooterLink linkText="Strona Główna" path="/"></FooterLink>
            <FooterLink linkText="Lista grobów" path="/groby"></FooterLink>
            <FooterLink linkText="Regulamin Usługi" path="/regulamin"></FooterLink>
            <FooterLink linkText="Polityka Prywatności" path="/polityka-prywatnosci"></FooterLink>
            <FooterLink target="_blank" linkText="Fundacja Stare powązki" path="https://fundacja.stare-powazki.pl/"></FooterLink>
          </div>
          <p
            className="m-0 self-stretch relative leading-[24px] cursor-default lg:cursor-none md:text-center md:self-stretch md:w-auto sm:text-center"
            style={textpStyle}
          >
            © 2023 Fundacja Stare Powązki
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
