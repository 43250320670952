import {FunctionComponent, useState, useEffect, FC} from "react";
import CardOption from "../components/CardOption";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { fetchGrave } from "../api/Grave";
import ButtonLoader from "../components/ButtonLoader";
import {getFullName, SingleGraveType} from "../types/Grave";
import { formatDate } from "./Graves";
import GraveImage from "../components/GraveImage"
import { GravePhoto } from "../types/Grave";
import Markdown from "markdown-to-jsx";
import GoogleMapReact from 'google-map-react';
import StyledContent from "../components/CmsContent";
import MapMarker from "../components/MapMarker";
import {
  GRAVE_SUPPORT_AMOUNT_MIN_GROUP,
  GRAVE_SUPPORT_AMOUNT_MIN_INDIVIDUAL,
  GRAVE_SUPPORT_TYPE_GROUP,
  GRAVE_SUPPORT_TYPE_INDIVIDUAL
} from "../types/Support";

const Grave: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [grave, setGrave] = useState<SingleGraveType | null>(null);
  const [loading, setLoading] = useState(true);

  const PageData = {
    siteTitle: 'Stare Powązki',
    pageTitle: 'Grób: ' + id,
    canonicalUrl: '/groby/' + id
  }

  const loadGrave = () => {
    setLoading(true);

    fetchGrave(id).then(response => {
      if (!response.data.supportIndividual && !response.data.supportGroup) {
        navigate('/404');
      }

      setGrave(response.data);
      setLoading(false);
    }).catch(error => {
      navigate('/404');
    });
  }

  useEffect(() => {
    if (grave === null) { loadGrave(); }
  }, []);

  return (
    <Layout pageData={ PageData }>
      <>{ loading && <div className="w-full justify-center flex"><ButtonLoader /></div> }</>
      <>
        { grave !== null &&
            <>
              <section className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-left text-[1rem] text-darkslategray font-header-bold sm:pl-[1rem] sm:pr-[1rem] sm:box-border">
                <h2 className="m-0 self-stretch relative text-[2rem] leading-[3rem] font-bold font-inherit">
                  { getFullName(grave) }
                </h2>
                <div className="self-stretch flex flex-row items-start justify-start gap-[2rem] text-tan font-text-regular-body">
                    { grave.dateDeath !== '' && typeof grave.dateDeath !== "undefined" && grave.dateDeath !== null &&
                        <label className="cursor-pointer relative leading-[2rem]">
                            zm. { formatDate(grave.dateDeath) }
                        </label>
                    }
                    { grave.dateBirth !== '' && typeof grave.dateBirth !== "undefined" && grave.dateBirth !== null &&
                        <label className="cursor-pointer relative leading-[2rem]">
                            ur. { formatDate(grave.dateBirth) }
                        </label>
                    }
                </div>
                <div className="self-stretch rounded-lg flex flex-row pt-[0.5rem] px-[0rem] pb-[0rem] items-start justify-start gap-[2rem] text-dimgray-200">
                  <div className="flex flex-row items-start justify-start gap-[0.25rem]">
                    <div className="relative tracking-[-0.04em] leading-[1.5rem]">Kwatera:</div>
                    <b className="relative tracking-[-0.04em] leading-[1.5rem] text-darkslategray">{ grave.quarter }</b>
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[0.25rem]">
                    <div className="relative tracking-[-0.04em] leading-[1.5rem]">Rząd:</div>
                    <b className="relative tracking-[-0.04em] leading-[1.5rem] text-darkslategray">{ grave.row }</b>
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[0.25rem]">
                    <div className="relative tracking-[-0.04em] leading-[1.5rem]">Miejsce:</div>
                    <b className="relative tracking-[-0.04em] leading-[1.5rem] text-darkslategray">{ grave.place }</b>
                  </div>
                </div>
              </section>
              { (grave.supportGroup || grave.supportIndividual) &&
                  <section className="w-full flex flex-col items-center justify-start gap-[1rem] max-w-[64rem] text-left text-[1.5rem] text-darkslategray font-header-bold sm:w-full sm:pl-[1rem] sm:pr-[1rem] sm:box-border">
                    <h3 className="m-0 self-stretch relative text-inherit leading-[2rem] font-bold font-inherit">
                      Dostępne opcje opieki
                    </h3>
                    <div className="self-stretch flex flex-row flex-wrap gap-[2rem] lg:w-full lg:items-center lg:justify-start md:h-auto">
                      { grave.supportIndividual && <CardOption graveId={ grave.id } typeName="Patronat Indywidualny" type={ GRAVE_SUPPORT_TYPE_INDIVIDUAL } length="okres: 12 miesięcy" pricing={ `min. ${ GRAVE_SUPPORT_AMOUNT_MIN_INDIVIDUAL } zł` } /> }
                      { grave.supportGroup && <CardOption graveId={ grave.id } typeName="Patronat Grupowy" type={ GRAVE_SUPPORT_TYPE_GROUP } length="okres: 12 miesięcy" pricing={ `min. ${ GRAVE_SUPPORT_AMOUNT_MIN_GROUP } zł` } /> }
                    </div>
                  </section>
              }

                { grave.photos.length > 0 &&
                    <section className="w-full flex flex-col justify-start gap-[1rem] text-left text-[1.5rem] text-dimgray-200 font-header-bold sm:pl-[1rem] sm:pr-[1rem] sm:box-border">
                      <div className="grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 gap-[2rem] lg:w-full">
                        {
                          grave.photos.map((photo:GravePhoto) => <GraveImage key={photo.id} photo={photo} alt={ getFullName(grave) } />)
                        }
                      </div>
                    </section>
                }
                { grave.descriptionLong !== null && grave.descriptionLong !== '' &&
                    <section className="w-full flex flex-col items-start justify-start gap-[1rem] max-w-[64rem] text-left text-[1.5rem] text-dimgray-200 font-header-bold sm:pl-[1rem] sm:pr-[1rem] sm:box-border">
                      <StyledContent>
                        <Markdown>{ grave.descriptionLong }</Markdown>
                      </StyledContent>
                    </section>
                }
              <section className="self-stretch flex flex-col items-center justify-start gap-[2rem] text-left text-[1.5rem] text-darkslategray font-header-bold sm:pl-[1rem] sm:pr-[1rem] sm:box-border">
                <h3 className="m-0 self-stretch relative text-inherit leading-[2rem] font-bold font-inherit">
                  Lokalizacja grobu
                </h3>
                <div className="self-stretch relative max-w-full overflow-hidden h-[23.63rem] shrink-0 object-cover">
                  <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.NODE_ENV === 'production' ? 'AIzaSyDwzHuQFsfRePWQkQk8lSMYubeRfbrac3E' : '' }}
                      center={
                        {
                          lat: grave.lat,
                          lng: grave.lng
                        }
                      }
                      zoom={ 18 }
                  >
                    <MapMarker lat={ grave.lat } lng={ grave.lng } icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png" />
                  </GoogleMapReact>
                </div>
              </section>
            </>
        }
      </>
    </Layout>
  );
};

export default Grave;
