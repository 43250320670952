import React, { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { GravePhoto } from "../types/Grave";

type CardSearchResultHistoricalType = {
  id?: number;
  imageDimensions?: GravePhoto | null | undefined;
  personName?: string;
  burialPlotNumber?: string;
  imageCode?: string;
  dateOfBirth?: string | null | undefined;
};

const CardStyled = styled.div`
  ${tw`relative rounded-xl bg-white shadow-[0px_4px_16px_rgba(0,_0,_0,_0.08)] w-80 flex flex-col p-4 box-border items-start justify-start gap-[16px] cursor-pointer min-w-[272px] max-w-[320px] [transition:0.5s] ease-[ease-in-out] text-left text-5xl text-darkslategray font-text-regular-body hover:shadow-[0px_8px_32px_rgba(0,_0,_0,_0.12)] hover:cursor-pointer lg:max-w-full`}
`
const ImageContainerStyled = styled.div`${tw`self-stretch relative rounded-lg h-[200px] overflow-hidden shrink-0`}`
const ImageStyled = styled.img`${tw`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover`}`
const InfoContainer = styled.div`${tw`self-stretch flex flex-col items-start justify-start gap-[4px]`}`
const InfoHeaderContainer = styled.div`${tw`self-stretch flex flex-row items-start justify-start font-header-bold`}`
const InfoHeader = styled.div`
  ${'font-inherit'}
  ${tw`m-0 flex-1 relative text-inherit leading-[32px] font-bold cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden hover:cursor-pointer lg:text-xl lg:leading-[32px] md:text-xl md:leading-[32px] sm:text-xl sm:leading-[32px]`}
`
const QuarterLabel = styled.label`${tw`cursor-pointer self-stretch relative text-base leading-[32px] text-dimgray-200`}`
const DateLabel = styled.label`${tw`cursor-pointer flex-1 relative leading-[24px]`}`
const DateContainer = styled.label`${tw`self-stretch flex flex-row items-start justify-start gap-[4px] text-sm text-tan`}`
const CrossIcon = styled.img`${tw`relative w-6 h-6 overflow-hidden shrink-0`}`

const CardSearchResultHistorical: FC<CardSearchResultHistoricalType> = ({
  id,
  imageDimensions,
  personName,
  burialPlotNumber,
  imageCode,
  dateOfBirth
}) => {
  const image = imageDimensions !== null && typeof imageDimensions !== "undefined" ? imageDimensions.url : '/placeholder.png';

  return (
      <Link to={ '/groby/' + id } className="no-underline">
        <CardStyled>
          <ImageContainerStyled>
            <ImageStyled alt={ personName } src={ image } />
          </ImageContainerStyled>
          <InfoContainer>
            <InfoHeaderContainer>
              <InfoHeader>{ personName }</InfoHeader>
            </InfoHeaderContainer>
            <QuarterLabel>{burialPlotNumber}</QuarterLabel>
            <DateContainer>
              { dateOfBirth !== null && dateOfBirth !== '' && typeof dateOfBirth !== "undefined" &&
                <>
                  <CrossIcon src={imageCode} />
                  <DateLabel>{dateOfBirth}</DateLabel>
                </>
              }
            </DateContainer>
          </InfoContainer>
        </CardStyled>
      </Link>
  );
};

export default CardSearchResultHistorical;
