import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import { FormSection, SectionHeader } from "./form/SupportFormStyles";
import CardLink from "./CardLink";
import {PAYMENT_TYPE_BANK_TRANSFER, PAYMENT_TYPE_P24} from "../types/Support";

export const PaymentContainer = styled.div`${tw`w-[37.5rem] flex flex-col items-center justify-start gap-[2rem] max-w-[37.5rem] sm:w-full sm:pl-[1rem] sm:pr-[1rem] sm:box-border`}`

type PaymentType = {
    handlePaymentType: CallableFunction
}

const SupportPayment: FC<PaymentType> = ({ handlePaymentType }) => {
  return (
      <FormSection>
          <SectionHeader>Wybierz formę płatności</SectionHeader>
          <PaymentContainer className="w-[37.5rem] flex flex-col items-center justify-start gap-[2rem] max-w-[37.5rem] sm:w-full sm:pl-[1rem] sm:pr-[1rem] sm:box-border">
              <CardLink onClick={ handlePaymentType } cardType={ PAYMENT_TYPE_P24 } cardTitle="Przelewy 24" />
              <CardLink onClick={ handlePaymentType } cardType={ PAYMENT_TYPE_BANK_TRANSFER } cardTitle="Przelew Bankowy" />
          </PaymentContainer>
      </FormSection>
  );
};

export default SupportPayment;
