import React, {FC, useEffect, useState} from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const ErrorContainer = styled.div`${tw`border-red bg-lightRed text-red font-text-regular-body`}`
const ErrorText = styled.span`${tw``}`

type ErrorType = {
    errors: any;
}

const RequestErrors: FC<ErrorType> = ({ errors }) => {
  const [errorsArray, setErrorsArray] = useState<string[]>([]);

  const generateErrors = () => {
      let eArray: string[] = [];
      let property: keyof typeof errors;

      for (property in errors) {
          eArray.push(errors[property]);
      }

      setErrorsArray(eArray);
  }

    useEffect(() => {
        generateErrors();
    }, []);

  return (
      <ErrorContainer className="rounded p-5">
          {
              errorsArray.map((error) => <ErrorText>{ error }</ErrorText>)
          }
      </ErrorContainer>
  );
};

export default RequestErrors;
