import { FunctionComponent } from "react";
import styled from 'styled-components';
import tw from 'twin.macro'
import { Link } from "react-router-dom";

type FooterLinkType = {
  path: string;
  linkText: string;
  target?: string;
};

const SpanStyles = tw`[text-decoration:none] relative leading-[24px] text-[inherit] cursor-pointer [transition:0.4s] ease-[ease-in-out] hover:text-tan lg:[transition:0.4s] lg:ease-[ease-in-out]`
const StyledSpan = styled.span`${SpanStyles}`

const FooterLink: FunctionComponent<FooterLinkType> = ({
  path,
  linkText,
  target
}) => {
  return (
      <Link target={ target } to={ path }  className="[text-decoration:none] flex flex-row items-center justify-center cursor-pointer text-[inherit]">
        <StyledSpan>{ linkText }</StyledSpan>
      </Link>
  );
};

export default FooterLink;
