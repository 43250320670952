import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const StyledHeader = styled.h1`
  ${`font-inherit`}
  ${tw`font-header-bold m-0 self-stretch relative text-inherit leading-[3rem] font-bold cursor-default lg:text-[2rem] lg:leading-[3rem] md:text-[2rem] md:leading-[2.5rem] md:text-left sm:text-left`}
`

type HeaderProps = {
    children: string | JSX.Element | JSX.Element[];
}

const PageHeader: FC<HeaderProps> = ({ children }) => {
    return (
        <StyledHeader>{ children }</StyledHeader>
    );
}

export default PageHeader;
