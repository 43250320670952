import {
  FunctionComponent,
  useState,
  useRef,
  useMemo,
  type CSSProperties,
  useCallback,
} from "react";
import PopupMenu from "../PopupMenu";
import PortalPopup from "../PortalPopup";
import { Link } from "react-router-dom";
import HeaderLink from "./HeaderLink";

type HeaderType = {
  dimensionCode?: string;

  /** Style props */
  headerPosition?: CSSProperties["position"];
  headerWidth?: CSSProperties["width"];
  headerPadding?: CSSProperties["padding"];
  headerBoxSizing?: CSSProperties["boxSizing"];
  headerAlignSelf?: CSSProperties["alignSelf"];
  contentMaxWidth?: CSSProperties["maxWidth"];
  logotypeFullHorizontalIcoWidth?: CSSProperties["width"];
  logotypeFullHorizontalIcoHeight?: CSSProperties["height"];
  menuGap?: CSSProperties["gap"];
  headerElementDefaultPadding?: CSSProperties["padding"];
  buttonTextFontSize?: CSSProperties["fontSize"];
  buttonTextLineHeight?: CSSProperties["lineHeight"];
  headerElementDefaultPadding1?: CSSProperties["padding"];
  buttonTextFontSize1?: CSSProperties["fontSize"];
  buttonTextLineHeight1?: CSSProperties["lineHeight"];
  headerElementDefaultPadding2?: CSSProperties["padding"];
  buttonTextFontSize2?: CSSProperties["fontSize"];
  buttonTextLineHeight2?: CSSProperties["lineHeight"];
  menuIconPadding?: CSSProperties["padding"];
  iconListWidth?: CSSProperties["width"];
  iconListHeight?: CSSProperties["height"];
};

const Header: FunctionComponent<HeaderType> = ({
  dimensionCode,
  headerPosition,
  headerWidth,
  headerPadding,
  headerBoxSizing,
  headerAlignSelf,
  contentMaxWidth,
  logotypeFullHorizontalIcoWidth,
  logotypeFullHorizontalIcoHeight,
  menuGap,
  headerElementDefaultPadding,
  buttonTextFontSize,
  buttonTextLineHeight,
  headerElementDefaultPadding1,
  buttonTextFontSize1,
  buttonTextLineHeight1,
  headerElementDefaultPadding2,
  buttonTextFontSize2,
  buttonTextLineHeight2,
  menuIconPadding,
  iconListWidth,
  iconListHeight,
}) => {
  const menuIconContainerRef = useRef<HTMLDivElement>(null);
  const [isPopupMenuOpen, setPopupMenuOpen] = useState(false);
  const HeaderStyle: CSSProperties = useMemo(() => {
    return {
      position: headerPosition,
      width: headerWidth,
      boxSizing: headerBoxSizing,
      alignSelf: headerAlignSelf,
    };
  }, [
    headerPosition,
    headerWidth,
    headerPadding,
    headerBoxSizing,
    headerAlignSelf,
  ]);

  const content1Style: CSSProperties = useMemo(() => {
    return {
      maxWidth: contentMaxWidth,
    };
  }, [contentMaxWidth]);

  const logotypeFullHorizontalIcon1Style: CSSProperties = useMemo(() => {
    return {
      width: logotypeFullHorizontalIcoWidth,
      height: logotypeFullHorizontalIcoHeight,
    };
  }, [logotypeFullHorizontalIcoWidth, logotypeFullHorizontalIcoHeight]);

  const menuStyle: CSSProperties = useMemo(() => {
    return {
      gap: menuGap,
    };
  }, [menuGap]);

  const headerElementDefaultStyle: CSSProperties = useMemo(() => {
    return {
      padding: headerElementDefaultPadding,
    };
  }, [headerElementDefaultPadding]);

  const buttonText2Style: CSSProperties = useMemo(() => {
    return {
      fontSize: buttonTextFontSize,
      lineHeight: buttonTextLineHeight,
    };
  }, [buttonTextFontSize, buttonTextLineHeight]);

  const headerElementDefault1Style: CSSProperties = useMemo(() => {
    return {
      padding: headerElementDefaultPadding1,
    };
  }, [headerElementDefaultPadding1]);

  const buttonText3Style: CSSProperties = useMemo(() => {
    return {
      fontSize: buttonTextFontSize1,
      lineHeight: buttonTextLineHeight1,
    };
  }, [buttonTextFontSize1, buttonTextLineHeight1]);

  const headerElementDefault2Style: CSSProperties = useMemo(() => {
    return {
      padding: headerElementDefaultPadding2,
    };
  }, [headerElementDefaultPadding2]);

  const buttonText4Style: CSSProperties = useMemo(() => {
    return {
      fontSize: buttonTextFontSize2,
      lineHeight: buttonTextLineHeight2,
    };
  }, [buttonTextFontSize2, buttonTextLineHeight2]);

  const menuIconStyle: CSSProperties = useMemo(() => {
    return {
      padding: menuIconPadding,
    };
  }, [menuIconPadding]);

  const iconListStyle: CSSProperties = useMemo(() => {
    return {
      width: iconListWidth,
      height: iconListHeight,
    };
  }, [iconListWidth, iconListHeight]);

  const openPopupMenu = useCallback(() => {
    setPopupMenuOpen(true);
  }, []);

  const closePopupMenu = useCallback(() => {
    setPopupMenuOpen(false);
  }, []);

  return (
    <>
      <nav
        className="m-0 mq350small:px-[0rem] sm:px-[0rem] md:px-[2rem] lg:px-[1rem] relative w-full flex flex-row py-4 box-border items-center justify-center lg:pt-4 lg:box-border md:box-border sm:items-center sm:justify-start sm:box-border"
        style={HeaderStyle}
      >
        <div
          className="flex-1 flex flex-row items-center justify-between max-w-[1024px] sm:items-center sm:justify-between sm:gap-[0px] sm:pl-0 sm:pt-0 sm:pr-0 sm:box-border"
          style={content1Style}
        >
          <Link to="/">
            <img
              className="relative w-[219px] h-12 md:w-[146px] md:h-8 sm:w-[146px] sm:h-8 mq350small:w-[146px] mq350small:h-8"
              alt=""
              src={dimensionCode}
              style={logotypeFullHorizontalIcon1Style}
            />
          </Link>
          <div
            className="flex flex-row items-end justify-center gap-[32px] mq350small:hidden sm:hidden sm2:hidden"
            style={menuStyle}
          >
            <HeaderLink linkText="Strona Główna" path="/" />
            <HeaderLink linkText="Lista Grobów" path="/groby" />
            <HeaderLink linkText="Regulamin" path="/regulamin" />
          </div>
          <div
            className="flex-row p-3 items-center justify-start cursor-pointer mq350small:flex sm:flex sm2:flex md:hidden lg:hidden"
            ref={menuIconContainerRef}
            onClick={openPopupMenu}
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/iconlist.svg"
              style={iconListStyle}
            />
          </div>
        </div>
      </nav>
      {isPopupMenuOpen && (
        <PortalPopup
          placement="Top left"
          relativeLayerRef={menuIconContainerRef}
          onOutsideClick={closePopupMenu}
        >
          <PopupMenu onClose={closePopupMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default Header;
