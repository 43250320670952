import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import HeaderLinkMobile from "./layout/HeaderLinkMobile";

type PopupMenuType = {
  onClose?: () => void;
};

const CloseButtonContainer = styled.div`
  .close {
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .close:before, .close:after {
    position: absolute;
    content: ' ';
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    height: 24px;
    width: 2px;
    background-color: #b99874;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  ${tw`mr-3 flex flex-col items-center w-[24px]`}
`

const PopupMenu: FunctionComponent<PopupMenuType> = ({ onClose }) => {
  return (
    <div className="relative bg-white w-full h-[53.25rem] flex flex-col py-[1rem] px-[0rem] box-border items-start justify-start max-w-full max-h-full overflow-auto">
      <div className="self-stretch flex flex-col items-center justify-end gap-[2rem] sm:flex sm2:flex mq350small:flex md:hidden lg:hidden">
        <div className="w-full flex flex-row justify-between gap-2">
          <Link to="/" className="ml-3">
            <img className="relative h-12" alt="" src="/logotypefullhorizontal2.svg"/>
          </Link>
          <CloseButtonContainer onClick={ onClose }><span className="close"></span></CloseButtonContainer>
        </div>
        <HeaderLinkMobile linkText="Strona Główna" path="/" />
        <HeaderLinkMobile linkText="Lista Grobów" path="/groby" />
        <HeaderLinkMobile linkText="Regulamin" path="/regulamin" />
      </div>
    </div>
  );
};

export default PopupMenu;
