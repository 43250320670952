import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

type TextCopyType = {
  title: string;
  text: string;
  allowCopy: boolean
};

const StyledMainBlock = styled.div`
  position: unset;
  gap: 1rem;
  ${tw`relative w-full max-w-[600px] flex flex-row items-center justify-start gap-[16px] text-left text-xl text-darkslategray font-text-regular-body`}
`

const StyledInnerBlock = styled.div`
  gap: 0.25rem;
  ${tw`flex-1 flex flex-col items-start justify-start gap-[4px]`}
`

const StyledHeader = styled.h5`
  font-size: 1.25rem;
  line-height: 2.5rem;
  ${ `font-inherit` }
  ${tw`m-0 self-stretch relative text-inherit leading-[40px] font-semibold`}
`

const StyledParagraph = styled.p`
  font-size: 0.88rem;
  line-height: 1.5rem;
  ${tw`m-0 self-stretch relative text-sm leading-[24px] text-dimgray-200`}
`

const StyledImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: unset;
  ${tw`relative w-6 h-6 overflow-hidden shrink-0 cursor-pointer`}
`

const TextCopy: FC<TextCopyType> = ({title, text, allowCopy}) => {

  const handleCopyOnClick = () => {
    navigator.clipboard.writeText(text.replaceAll('<br>', ' '))
  }

  return (
    <StyledMainBlock>
      <StyledInnerBlock>
        <StyledHeader>{ title }</StyledHeader>
        <StyledParagraph>
          {text.split("<br>").map((item, key) => {
            return <span key={key}>{item}<br/></span>
          })}
        </StyledParagraph>
      </StyledInnerBlock>
      { allowCopy &&
          <StyledImage alt="Kopiuj" title="Kopiuj" src="/iconcopysimple.svg" onClick={ () => handleCopyOnClick() } />
      }
    </StyledMainBlock>
  );
};

export default TextCopy;
