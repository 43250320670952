import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

type ButtonPrimaryMediumDefaultType = {
  buttonText: string;
  buttonType?: "button" | "submit" | "reset" | undefined;
  handleOnClick?: React.MouseEventHandler;
  formId?: string | undefined;
};

const StyledButtonContainerStyles = tw`
  relative rounded-lg bg-gray-100 w-[343px] overflow-hidden flex flex-row py-3 px-4 box-border items-center 
  justify-center cursor-pointer [transition:0.5s] ease-[ease-in-out] text-center text-base text-white 
  font-header-bold hover:bg-tan md:hover:bg-gray-100 mq350small:hover:bg-gray-100
`
export const StyledButtonContainer = styled.button`
  position: unset !important;
  width: 19rem !important;
  padding: 0.75rem 1rem !important;
  ${StyledButtonContainerStyles}
`

export const StyledButtonText = styled.b`
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  ${tw`flex-1 relative tracking-[-0.04em] leading-[24px]`}
`

const ButtonPrimaryMediumDefault: FC<ButtonPrimaryMediumDefaultType> = ({
    buttonType,
    buttonText = "Button Text",
    handleOnClick,
    formId

}) => {
  return (
      <StyledButtonContainer form={ formId } type={ buttonType } onClick={handleOnClick} >
        <StyledButtonText>{buttonText}</StyledButtonText>
      </StyledButtonContainer>
  );
};

export default ButtonPrimaryMediumDefault;
