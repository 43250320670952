export const cacheData = (key: string, data: object | string) => {
    sessionStorage.setItem(key, JSON.stringify(data));
}

export const getCachedData = (key: string) => {
    const cachedData = sessionStorage.getItem(key);

    if (cachedData) {
        return JSON.parse(cachedData);
    }

    return null;
}
