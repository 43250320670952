import React, { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

type ContentProps = {
    children: string | JSX.Element | JSX.Element[];
}

const StyledCmsContent = styled.div`
  ${tw`self-stretch flex flex-col items-start justify-start gap-[2rem] sm:pl-[1rem] sm:pr-[1rem] sm:box-border`}
  h2 {font-inherit ${tw`font-header-bold py-[2rem] m-0 self-stretch relative text-[2rem] leading-[3rem] font-bold  sm:text-[1.5rem] sm:leading-[2.5rem]`}}
  h3 {font-inherit ${tw`font-header-bold py-[2rem] m-0 self-stretch relative text-[1.5rem] leading-[2rem] font-bold sm:text-[1rem] sm:leading-[2rem]`}}
  p {${tw`font-normal text-sm block m-0 self-stretch relative leading-[1.5rem] font-text-regular-body text-dimgray-200 `}
`

const StyledContent: FC<ContentProps> = ({children}) => {
    return <StyledCmsContent>{ children }</StyledCmsContent>;
};

export default StyledContent;
