import axios from "./axios";

export const fetchGraves = (page: number, size: number) => {
    return axios.get('/api/grave/search', {
        params: {
            locale: 'PL',
            supportAllowed: true,
            page: page,
            size: size
        }
    })
}

export const fetchGrave = (id: string | undefined) => {
    return axios.get('/api/grave', {
        params: { id: id }
    })
}
