import React, {FC, useState, useEffect} from "react";
import Layout from "./layout/Layout";
import { fetchPage } from "../api/CmsPage";
import { redirect } from "react-router-dom";
import {ApiPageType, CmsPageType} from "../types/PageData";
import ButtonLoader from "./ButtonLoader";
import StyledContent from "./CmsContent";
import Markdown from "markdown-to-jsx";
import PageHeader from "./PageHeader";

export interface CmsPageProps {
    cmsPage: CmsPageType
}

const CmsPage: FC<CmsPageProps> = ({ cmsPage }) => {
  const [pageContent, setPageContent] = useState<ApiPageType | null>(null);
  const [loading, setLoading] = useState(true);

  const loadPageContent = () => {
      setLoading(true);

      fetchPage(cmsPage.key).then(response => {
        if (response.status === 404) {
          redirect('/404')
        }

        setPageContent(response.data);
        setLoading(false);
      }).catch(error => { console.error(error); });
    }

    useEffect(() => {
        if (pageContent === null) {
            loadPageContent();
        }
    }, []);

  return (
    <Layout pageData={ cmsPage.page }>
        <section className="w-full max-w-[64rem] lg:w-full lg:max-w-[64rem] sm:max-w-[24.75rem]">
            <>
                { loading &&
                    <div className="w-full justify-center flex">
                        <ButtonLoader />
                    </div>
                }
            </>
            <>
                { pageContent !== null && typeof pageContent !== 'undefined' &&
                    <StyledContent>
                        <PageHeader>{ pageContent.title }</PageHeader>
                        <Markdown>{ pageContent.content }</Markdown>
                    </StyledContent>
                }
            </>
        </section>
    </Layout>
  );
};

export default CmsPage;
