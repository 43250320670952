export interface GravePhoto {
    id: number,
    url: string,
    order: number
}

interface GraveAudio {
    id: number,
    url: string,
    order: number
}

export default interface GraveType {
    dateDeath: string,
    historical: boolean,
    id: number,
    lastName: string,
    lat: number,
    lng: number,
    locale: string,
    name: string,
    photo?: GravePhoto | null | undefined,
    place: string,
    quarter: string,
    row: string,
    type: string,
};

export interface SingleGraveType {
    id: number,
    name: string,
    lastName: string,
    locale: string,
    quarter: string,
    row: string,
    place: string,
    graveId: number,
    lat: number,
    lng: number,
    historical: boolean,
    dateDeath: string,
    dateBirth: string,
    supportIndividual: boolean,
    supportGroup: boolean,
    descriptionShort: string,
    descriptionLong: string,
    descriptionEpitaph: string,
    type: string,
    photos: Array<GravePhoto>,
    audio: Array<GraveAudio>
};

export const getFullName = (grave:GraveType) => {
    const firstName = grave.name !== null ? grave.name : '';
    const lastName = grave.lastName !== null ? grave.lastName : '';

    return `${firstName} ${lastName}`.trim();
}
