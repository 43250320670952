export const PAYMENT_TYPE_P24 = 'P24';
export const PAYMENT_TYPE_BANK_TRANSFER = 'BANK_TRANSFER';
export const GRAVE_SUPPORT_TYPE_INDIVIDUAL = 'INDIVIDUAL';
export const GRAVE_SUPPORT_TYPE_GROUP = 'GROUP';
export const GRAVE_SUPPORT_AMOUNT_MIN_INDIVIDUAL = 1000;
export const GRAVE_SUPPORT_AMOUNT_MIN_GROUP = 10;
export const P24_SDK_VERSION = '1.0.0.0';

export type Supporter = {
    name: string,
    email: string,
    city: string,
    street: string,
    zipCode: string,
    houseNumber: string,
    apartmentNumber: string,
    country: string,
    amount: number,
    hideMyData: boolean,
    acceptRegulations: boolean,
    acceptDataProcessing: boolean
}

export type GraveSupport = {
    type: string,
    name: string,
    email: string,
    city: string,
    street: string,
    zipCode: string,
    houseNumber: string,
    apartmentNumber: string,
    country: string,
    amount: number,
    hideMyData: boolean,
    acceptRegulations: boolean,
    acceptDataProcessing: boolean,
    graveId: number,
    paymentType: string,
    p24SdkVersion: string,
    redirectOnComplete: string,
}
