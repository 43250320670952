import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const StyledContainer = styled.div`${tw`self-stretch flex flex-col items-center justify-start`}`
const StyledInnerContainer = styled.div`${tw`w-full flex flex-col items-center justify-start gap-[2rem] max-w-[64rem] md:pl-[2rem] md:pr-[2rem] md:box-border sm:pl-[1rem] sm:pr-[1rem] sm:box-border`}`
const StyledHeader = styled.h2`font-inherit ${tw`m-0 self-stretch relative text-inherit leading-[3rem] font-bold md:text-[1.5rem] md:leading-[2.5rem]`}`
const StyledParagraph = styled.p`${tw`m-0 self-stretch relative text-[1rem] leading-[2rem] font-text-regular-body text-dimgray-200`}`

const StyledBody = styled.div`${tw`self-stretch flex flex-col py-[0rem] box-border items-center justify-start gap-[4rem] text-center text-[2rem] text-darkslategray font-header-bold lg:self-stretch lg:w-auto lg:gap-[5rem] lg:pl-[6.5rem] lg:pr-[6.5rem] lg:box-border md:gap-[3rem] md:pl-[2rem] md:pr-[2rem] md:box-border sm:gap-[2rem] sm:items-start sm:justify-start sm:pl-[0rem] sm:pr-[0rem] sm:box-border mq350small:gap-[2rem] mq350small:pl-[0rem] mq350small:pr-[0rem] mq350small:box-border`}`

const PaymentConfirmationHeader: FC = () => {
  return (
      <StyledBody>
          <StyledContainer>
              <StyledInnerContainer>
                  <StyledHeader>Serdecznie dziękujemy za wsparcie misji opieki nad starymi powązkami.</StyledHeader>
                  <StyledParagraph>Potwierdzenie przelewu wysłaliśmy na podany adres email.</StyledParagraph>
              </StyledInnerContainer>
          </StyledContainer>
      </StyledBody>
  );
};

export default PaymentConfirmationHeader;
