import { FunctionComponent } from "react";
import styled from 'styled-components';
import tw from 'twin.macro'
import { Link } from "react-router-dom";

type HeaderLinkType = {
  path: string;
  linkText: string;
  target?: string;
};

const TextStyles = tw`relative text-base tracking-[-0.04em] leading-[24px] font-header-bold text-dimgray-200 text-center cursor-pointer [transition:0.4s] ease-[ease-in-out] hover:text-tan hover:[transition:0.4s] hover:ease-[ease-in-out]`
const StyledText = styled.b`
  font-size: 1rem;
  line-height: 1.5rem;
  ${TextStyles}
`

const HeaderLink: FunctionComponent<HeaderLinkType> = ({
  path,
  linkText,
  target
}) => {
  return (
      <Link target={ target } to={ path }  className="no-underline cursor-pointer [border:none] py-3 px-0 bg-[transparent] overflow-hidden flex flex-row items-center justify-center">
        <StyledText>{ linkText }</StyledText>
      </Link>
  );
};

export default HeaderLink;
