import { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

type CardLinkType = {
  cardTitle: string;
  cardType: string;
  onClick: CallableFunction
};

const CardContainer = styled.div`
  position: unset !important;
  border-bottom: 0.5px solid #b1c0c9 !important;
  width: unset !important;
  padding: 0.75rem !important;
  gap: 0.5rem !important;
  text-decoration: none !important;
  align-self: stretch !important;
  ${tw`cursor-pointer hover:bg-silver relative [transition:0.5s] ease-[ease-in-out] box-border w-[343px] flex flex-row p-3 items-center justify-start gap-[8px] text-left text-base text-darkslategray font-header-bold border-b-[0.5px] border-solid border-darkslategray`}
`

const CardTitleContainer = styled.div`${tw`flex-1 flex flex-col items-start justify-start`}`
const CardTitle = styled.b`
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  ${tw`self-stretch relative tracking-[-0.04em] leading-[24px]`}
`

const CardImage = styled.img`
  width: 1rem !important;
  height: 1rem !important;
  ${tw`relative w-4 h-4 overflow-hidden shrink-0`}
`

const CardLink: FC<CardLinkType> = ({ cardTitle, cardType, onClick }) => {
  return (
      <CardContainer onClick={ () => onClick(cardType) }>
        <CardTitleContainer>
          <CardTitle>{ cardTitle }</CardTitle>
        </CardTitleContainer>
        <CardImage src="/iconcaretright1.svg" />
      </CardContainer>
  );
};

export default CardLink;
