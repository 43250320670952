import * as React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import PageData from "../../types/PageData";
import { Helmet } from 'react-helmet'

type LayoutProps = {
    children: string | JSX.Element | JSX.Element[];
    pageData?: PageData
}

const Layout = ({children, pageData} : LayoutProps) => {
    return (
        <>
            <Helmet>
                <title>{ pageData?.siteTitle } | { pageData?.pageTitle }</title>
                { pageData?.canonicalUrl && <link rel="canonical" href={ pageData?.canonicalUrl } />}
            </Helmet>
            <div className="m-auto max-w-[64rem] relative bg-white w-full overflow-hidden flex flex-col items-center justify-start gap-[5rem] lg:gap-[4rem] md:gap-[3rem] md:items-center md:justify-start md:pl-[0rem] md:pr-[0rem] md:box-border sm:gap-[2rem] sm:pl-[1rem] sm:pr-[1rem] sm:box-border mq350small:gap-[2rem] mq350small:pl-[1rem] mq350small:pr-[1rem] mq350small:box-border">
                <Header
                    dimensionCode="/logotypefullhorizontal2.svg"
                    headerPosition="unset"
                    headerWidth="unset"
                    headerPadding="1rem"
                    headerBoxSizing="border-box"
                    headerAlignSelf="stretch"
                    contentMaxWidth="64rem"
                    logotypeFullHorizontalIcoWidth="13.69rem"
                    logotypeFullHorizontalIcoHeight="3rem"
                    menuGap="2rem"
                    headerElementDefaultPadding="0.75rem 0rem"
                    buttonTextFontSize="1rem"
                    buttonTextLineHeight="1.5rem"
                    headerElementDefaultPadding1="0.75rem 0rem"
                    buttonTextFontSize1="1rem"
                    buttonTextLineHeight1="1.5rem"
                    headerElementDefaultPadding2="0.75rem 0rem"
                    buttonTextFontSize2="1rem"
                    buttonTextLineHeight2="1.5rem"
                    menuIconPadding="0.75rem"
                    iconListWidth="1.5rem"
                    iconListHeight="1.5rem"
                />
                <main className="self-stretch flex flex-col py-[0rem] box-border items-center justify-start gap-[8rem] min-h-[25rem] lg:self-stretch lg:w-auto lg:gap-[5rem] lg:pl-[1rem] lg:pr-[1rem] lg:box-border md:gap-[3rem] md:pl-[2rem] md:pr-[2rem] md:box-border sm:gap-[3rem] sm:items-start sm:justify-start sm:pl-[0rem] sm:pr-[0rem] sm:box-border mq350small:gap-[2rem] mq350small:pl-[0rem] mq350small:box-border">
                    { children }
                </main>
                <Footer
                    imageDimensions="/logotypefullhorizontal3@2x.png"
                    footerPosition="unset"
                    footerWidth="unset"
                    footerPadding="2.5rem 1rem"
                    footerAlignSelf="stretch"
                    contentGap="2rem"
                    contentMaxWidth="64rem"
                    logotypeFullHorizontalIcoWidth="13.69rem"
                    logotypeFullHorizontalIcoHeight="3rem"
                    linksPadding="1rem 0rem"
                    linksGap="2rem"
                    linkFontSize="0.88rem"
                    linkLineHeight="1.5rem"
                    linkFontSize1="0.88rem"
                    linkLineHeight1="1.5rem"
                    linkFontSize2="0.88rem"
                    linkLineHeight2="1.5rem"
                    linkFontSize3="0.88rem"
                    linkLineHeight3="1.5rem"
                    linkFontSize4="0.88rem"
                    linkLineHeight4="1.5rem"
                    textpFontSize="0.88rem"
                    textpLineHeight="1.5rem"
                />
            </div>
        </>
    );
}

export default Layout
