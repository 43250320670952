import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import { FormSection, SectionHeader } from "./form/SupportFormStyles";
import GraveType, {getFullName} from "../types/Grave";
import {GRAVE_SUPPORT_TYPE_GROUP, GRAVE_SUPPORT_TYPE_INDIVIDUAL} from "../types/Support";
import {formatDate} from "../pages/Graves";

const SummaryContainer = styled.div`${tw`self-stretch flex flex-col items-center justify-start gap-[1rem]`}`
const SummaryBody = styled.div`${tw`w-[37.5rem] flex flex-col items-center justify-start gap-[1rem] max-w-[37.5rem] text-[1.25rem] font-text-regular-body sm:w-full sm:pl-[0rem] sm:pr-[0rem] sm:box-border`}`
const ItemContainer = styled.div`${tw`self-stretch flex flex-col items-center justify-start gap-[0.25rem]`}`
const ItemHeader = styled.h5`
  ${'font-inherit'}
  ${tw`m-0 self-stretch relative text-inherit leading-[2.5rem] font-semibold`}
`
const ItemValue = styled.p`${tw`m-0 self-stretch relative text-[1rem] leading-[2rem] text-dimgray-200`}`
const GraveDataHeader = styled.h5`${`font-inherit`} ${tw`m-0 self-stretch relative text-[1.25rem] leading-[2.5rem] font-semibold text-darkslategray`}`

type SummaryType = {
    supportType: string | undefined;
    grave: GraveType;
    amount: number;
}

const SupportSummary: FC<SummaryType> = ({ supportType, amount, grave }) => {
  const getAgreementSubject = () => {
      switch (supportType) {
          case GRAVE_SUPPORT_TYPE_INDIVIDUAL:
              return 'Objęcie patronatem indywidualnym grobu na Cmentarzu Powązkowskim';
          case GRAVE_SUPPORT_TYPE_GROUP:
              return 'Objęcie patronatem grupowym grobu na Cmentarzu Powązkowskim';
      }
  }

  const getAgreementDates = () => {
      const endDate = new Date();
      endDate.setFullYear(endDate.getFullYear() + 1);

      const startDateString = formatDate(new Date().toString());
      const endDateString = formatDate(endDate.toString());

      return `${startDateString} - ${endDateString} (12 miesięcy)`;
  }

  return (
      <FormSection>
          <SummaryContainer>
              <SectionHeader>Podsumowanie</SectionHeader>
              <SummaryBody>
                  <ItemContainer>
                      <ItemHeader>Przedmiot umowy:</ItemHeader>
                      <ItemValue>{ getAgreementSubject() }</ItemValue>
                  </ItemContainer>
                  <ItemContainer>
                      <GraveDataHeader>Dane grobu</GraveDataHeader>
                      <ItemValue>{ getFullName(grave) }</ItemValue>
                      <ItemValue>{ `kwatera: ${grave.quarter}; rząd: ${grave.row}; miejsce: ${grave.place}` }</ItemValue>
                  </ItemContainer>
                  <ItemContainer>
                      <ItemHeader>Czas trwania umowy:</ItemHeader>
                      <ItemValue>{ getAgreementDates() }</ItemValue>
                  </ItemContainer>
                  <ItemContainer>
                      <ItemHeader>Kwota</ItemHeader>
                      <ItemValue>{ amount } zł</ItemValue>
                  </ItemContainer>
              </SummaryBody>
          </SummaryContainer>
      </FormSection>
  );
};

export default SupportSummary;
