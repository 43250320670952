import React, { FunctionComponent, useState, useEffect } from "react";
import ButtonPrimaryMediumDefault from "../components/ButtonPrimaryMediumDefault";
import FormContainer from "../components/FormContainer";
import {Link} from "react-router-dom";
import { fetchGraves } from "../api/Grave";
import Layout from "../components/layout/Layout";
import {cacheData, getCachedData} from "../cache/StorageCache";

const Home: FunctionComponent = () => {
  const [graves, setGraves] = useState([]);

  const PageData = {
      siteTitle: 'Stare Powązki',
      pageTitle: 'Strona główna',
      canonicalUrl: '/'
  }

  useEffect(() => {
      const cachedGraves = getCachedData('homepage_graves_list');

      if (cachedGraves !== null) {
          setGraves(cachedGraves);

          return;
      }

      fetchGraves(0, 6).then(response => {
          cacheData('homepage_graves_list', response.data.content);
          setGraves(response.data.content);
      }).catch(error => {
          console.error(error);
      });
  }, []);

  return (
    <Layout pageData={ PageData }>
        <section className="w-full flex flex-row items-start justify-start gap-[5rem] max-w-[64rem] text-left text-[2.5rem] text-darkslategray font-header-bold lg:w-full lg:max-w-[64rem] md:items-center md:justify-start sm:max-w-[24.75rem]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[2rem] min-w-[30rem] md:items-start md:justify-start sm:min-w-[12.5rem]">
                <h1 className="m-0 self-stretch relative text-inherit leading-[3.5rem] font-bold font-inherit lg:text-[2rem] lg:leading-[3rem] md:text-[2rem] md:leading-[2.5rem] md:text-left sm:text-left">
                    Patronat nad grobami
                </h1>
                <h2 className="m-0 self-stretch relative text-[2rem] leading-[3rem] font-bold font-inherit lg:text-[1.5rem] lg:leading-[2.5rem] md:text-[1.5rem] md:leading-[2.5rem] md:text-left sm:text-left">
                    Zostań patronem grobu na starych powązkach
                </h2>
                <p className="m-0 self-stretch relative text-[1rem] leading-[2rem] font-text-regular-body md:text-left sm:text-left">
                    Aplikacja CUSTO umożliwia objęcie opieką indywidualną i zbiorową
                    wybranych grobów na Cmentarzu Powązkowskim.
                </p>
                <Link to={'/groby'} className="no-underline">
                    <ButtonPrimaryMediumDefault buttonText="Wybierz grób do opieki"/>
                </Link>
            </div>
            <div className="flex-1 relative rounded-lg h-[22.5rem] overflow-hidden max-w-[25rem] lg:max-w-[20rem] lg:max-h-[20rem] md:hidden">
                <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/image29@2x.png"
                />
            </div>
        </section>
        <FormContainer graves={ graves }/>
    </Layout>
  );
};

export default Home;
