import React, { FC } from "react";

type MapMarkerType = {
    lat: number;
    lng: number;
    icon: string;
}

const MapMarker: FC<MapMarkerType> = ({ icon }) => <img src={ icon } />;

export default MapMarker;
