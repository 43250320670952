import { FC } from "react";
import { Field, ErrorMessage } from "formik";
import tw from 'twin.macro'
import styled from 'styled-components'
import RenderError from "./Error";

export const StyledInputContainer = styled.div`
  input {
    border: 1px solid #b99874;
    font-size: 0.88rem;
    padding: 0.75rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
  }
  p.error {
    color: #FF1400;
    font-size: 12px;
    line-height: 10px;
    font-weight: 400;
    padding-left: 15px;
  }
  ${tw`w-full`}
`
type TextInputProps = {
  name: string;
  placeholder: string;
  errors?: any;
  touched?: any;
}

const TextInput: FC<TextInputProps> = ({ name, placeholder , errors, touched}) => {

  return (
      <StyledInputContainer>
        <Field
            name={ name }
            type="text"
            className={ `font-text-regular-body text-sm bg-white self-stretch rounded-lg flex flex-row p-3 ${ errors && errors.length && touched > 0 ?`!border-red` : '' }` }
            placeholder={ placeholder }
        />
        <ErrorMessage name={ name } render={ RenderError } />
      </StyledInputContainer>
  );
}

export default TextInput;
