import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Graves from "./pages/Graves";
import Grave from "./pages/Grave";
import { useEffect } from "react";
import Page404 from "./pages/Page404";
import {Pages} from "./data/Pages";
import SupportConfirmation from "./pages/SupportConfirmation";
import GraveSupport from "./pages/GraveSupport";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/polityka-prywatnosci" element={<PrivacyPolicy cmsPage={ Pages.privacyPolicy } />} />
      <Route path="/regulamin" element={<Terms cmsPage={ Pages.termsOfService } />} />
      <Route path="/groby" element={<Graves />} />
      <Route path="/groby/:id" element={<Grave />} />
      <Route path="/groby/wsparcie/:type/:id" element={<GraveSupport />} />
      <Route path="/groby/wsparcie/potwierdzenie" element={<SupportConfirmation />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
export default App;
