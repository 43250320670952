import { FC } from "react";
import { Field, ErrorMessage } from "formik";
import tw from 'twin.macro'
import styled from 'styled-components'
import RenderError from "./Error";

const StyledContainer = styled.div`
  p.error {
    color: #FF1400;
    font-size: 12px;
    line-height: 10px;
    font-weight: 400;
    padding-left: 15px;
  }
  ${tw`w-full`}
`

const StyledInnerContainer = styled.div`
    gap: 0.13rem;
    max-width: 37.5rem;
    ${tw`w-full flex flex-row items-center justify-start gap-[2px] max-w-[600px]`}
`

const StyledInputContainer = styled.label`
  input {
    visibility: hidden;
    width: 0px;
    &:checked + span { background-color: #B99874; }
    &:checked + span:after { opacity: 1; }
  }
  ${tw`flex flex-row m-2 items-center justify-center`}
`

const StyledLabelContainer = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  width: 34.88rem;
  ${tw`relative leading-[16px] inline-block w-full max-w-[558px]`}
`

type CheckboxInputProps = {
  name: string;
  label: string;
  errors?: any;
  touched?: any;
}

export const StyledCustomCheckbox = styled.span`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 1px solid #B99874;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  text-align: center;
  
  .checkmark {
    margin-top: -1px;
    position: inherit;
    z-index: 1000000;
    font-family: arial;
    font-size: 20px;
    font-weight: normal;
    -ms-transform: scaleX(-1) rotate(-40deg);
    -webkit-transform: scaleX(-1) rotate(-40deg);
    transform: scaleX(-1) rotate(-40deg);
    color: #fff !important;
    display: inline-block;
  }
  
  &::after {
    text-align: center;
    content: '';
    color: #fff;
    width: 24px;
    height: 24px;
    background: #B99874;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
`

const CheckboxInput: FC<CheckboxInputProps> = ({ name, label, errors, touched }) => {
  return (
      <StyledContainer>
          <StyledInnerContainer>
              <StyledInputContainer>
                  <Field name={ name } type="checkbox"/>
                  <StyledCustomCheckbox>
                      <span className="checkmark">L</span>
                  </StyledCustomCheckbox>
              </StyledInputContainer>
              <StyledLabelContainer>{ label }</StyledLabelContainer>
          </StyledInnerContainer>
          <ErrorMessage name={ name } render={ RenderError } />
      </StyledContainer>
  );
}

export default CheckboxInput;
