import { FC } from "react";
import tw from 'twin.macro'
import styled from 'styled-components'
import {useNavigate} from "react-router-dom";

type CardOptionType = {
  graveId: number;
  typeName: string;
  pricing: string;
  length: string;
  type: string;
};

const CardOptionStyles = tw`w-full relative rounded-xl bg-white shadow-[0px_4px_16px_rgba(0,_0,_0,_0.08)] w-[496px] flex flex-col p-3 box-border items-start justify-start gap-[16px] text-left text-base text-darkslategray font-text-regular-body`;
const StyledCardOption = styled.div`
  position: unset;
  width: unset;
  padding: 0.75rem;
  gap: 1rem;
  flex: 1;
  max-width: 30rem;
  ${CardOptionStyles}
`

const CardOptionContentStyles = tw`self-stretch flex flex-row items-start justify-start gap-[16px]`
const StyledCardOptionContent = styled.div`
  gap: 1rem;
  ${CardOptionContentStyles}
`

const CardOptionImageStyles = tw`relative rounded-lg w-16 h-16 object-cover`
const StyledCardOptionImage = styled.img`
  width: 4rem;
  height: 4rem;
  ${CardOptionImageStyles}
`

const CardOptionInnerContentStyles = tw`w-full flex-1 flex flex-col items-start justify-start gap-[4px]`
const StyledCardOptionInnerContent = styled.div`
  gap: 0.25rem;
  ${CardOptionInnerContentStyles}
`

const CardOptionTypeTitleStyles = tw`w-full m-0 self-stretch relative text-inherit tracking-[-0.04em] leading-[24px] font-bold font-header-bold`
const StylesCardOptionTypeTitle = styled.h4`
  font-size: 1rem;
  line-height: 1.5rem;
  ${CardOptionTypeTitleStyles}
`

const PricingLabelStyles = tw`cursor-pointer self-stretch relative text-xs leading-[16px] text-dimgray-200`
const StyledPricingLabel = styled.label`
  font-size: 0.75rem;
  line-height: 1rem;
  ${PricingLabelStyles}
`

const LengthLabelStyles = tw`cursor-pointer flex-1 relative text-3xs leading-[16px] text-tan inline-block w-[396px]`
const StyledLengthLabel = styled.label`
  font-size: 0.63rem;
  line-height: 1rem;
  width: 24.75rem;
  ${LengthLabelStyles}
`

const ButtonStyles = tw`cursor-pointer [border:none] py-2 px-4 bg-tan self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center`
const StyledButton = styled.button`
  padding: 0.5rem 1rem;
  ${ButtonStyles}
`

const ButtonTextStyles = tw`flex-1 relative text-base tracking-[-0.04em] leading-[24px] font-header-bold text-white text-center`
const StyledButtonText = styled.b`
  font-size: 1rem;
  line-height: 1.5rem;
  ${ButtonTextStyles}
`

const CardOption: FC<CardOptionType> = ({ graveId, typeName, pricing, length, type}) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/groby/wsparcie/${ type }/${ graveId }`);
  }

  return (
    <StyledCardOption>
      <StyledCardOptionContent>
        <StyledCardOptionImage alt="" src="/image@2x.png" />
        <StyledCardOptionInnerContent>
          <StylesCardOptionTypeTitle>{ typeName }</StylesCardOptionTypeTitle>
          <StyledPricingLabel>{ pricing }</StyledPricingLabel>
          <StyledLengthLabel>{ length }</StyledLengthLabel>
        </StyledCardOptionInnerContent>
      </StyledCardOptionContent>
      <StyledButton onClick={ () => handleOnClick() }>
        <StyledButtonText>Zostań Patronem</StyledButtonText>
      </StyledButton>
    </StyledCardOption>
  );
};

export default CardOption;
