import React, { FunctionComponent } from "react";
import Layout from "../components/layout/Layout";

const Page404: FunctionComponent = () => {
  const PageData = {
      siteTitle: 'Stare Powązki',
      pageTitle: '404 - Strona nie istnieje',
      canonicalUrl: '/404'
  }

  return (
    <Layout pageData={ PageData }>
        <section className="w-full flex flex-row items-start justify-start gap-[5rem] max-w-[64rem] text-left text-[2.5rem] text-darkslategray font-header-bold lg:w-full lg:max-w-[64rem] md:items-center md:justify-start sm:max-w-[24.75rem]">
            <h1 className="m-0 self-stretch relative text-inherit leading-[3.5rem] font-bold font-inherit lg:text-[2rem] lg:leading-[3rem] md:text-[2rem] md:leading-[2.5rem] md:text-left sm:text-left">
                404 - Strona nie została znaleziona
            </h1>
        </section>
    </Layout>
  );
};

export default Page404;
